<template>
  <nav class="font-poppins fixed h-screen z-20">
    <div class="text-4xl p-3 font-caveat text-white text-center my-8">
      <a href="#top-page" class="text-5xl">Sawatdee</a>
    </div>
    <!--<div class="absolute"><img src="https://picsum.photos/150/80" alt="" /></div>-->
    <div class="p-5 text-white">
      <ul>
        <li><a href="#about" class="nav-link relative text-xl">A propos</a></li>
        <li><a href="#restaurant-menu" class="nav-link relative text-xl">Cartes</a></li>
        <li><a href="#contact" class="nav-link relative text-xl">Contact</a></li>
      </ul>
    </div>
    <div class="text-center mt-16">
      <a
        href="tel:06-26-64-50-77"
        class="
          text-lg
          border-2
          rounded-2xl
          p-2
          bg-white
          border-white
          hover:bg-black hover:text-white hover:border-black
        "
        >Commander</a
      >
    </div>
  </nav>
</template>
<script>
export default {};
</script>

<style scoped>
nav {
  background-color: rgba(0, 0, 0, 0.6);
}

.a-active {
  font-weight: bold;
}

.nav-link {
  margin: 0 2rem;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-link::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}
.nav-link:hover::after,
.nav-link:focus::after {
  transform: scale(1);
}
</style>
