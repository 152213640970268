<template>
  <swiper :slides-per-view="1" :space-between="10">
    <swiper-slide>
      <img
        src="../assets/slider/photo_slider_1.jpg"
        class="bg-cover bg-center"
        alt="Slider photo"
      />
    </swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/photo_slider_3.jpg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/photo_slider_2.jpg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/slider_4.jpg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/slider_5.jpeg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/slider_6.jpeg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
    <swiper-slide
      ><img src="../assets/slider/slider_7.jpg" class="bg-cover bg-center" alt="Slider photo"
    /></swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped>
img {
  background-image: ;
}
</style>
