<template>
  <nav class="w-full font-poppins flex justify-between fixed z-50">
    <div class="text-4xl p-3 font-caveat text-white">
      <a href="#top-page">Sawatdee</a>
    </div>
    <!--<div class="absolute"><img src="https://picsum.photos/150/80" alt="" /></div>-->
    <div class="flex w-full flex-wrap p-5 justify-end text-white">
      <a href="#about" class="nav-link relative">A propos</a>
      <a href="#restaurant-menu" class="nav-link relative">Cartes</a>
      <a href="#contact" class="nav-link relative">Contact</a>
    </div>
  </nav>
</template>
<script>
export default {};
</script>

<style scoped>
nav {
  background-color: rgba(0, 0, 0, 0.3);
}

.a-active {
  font-weight: bold;
}

.nav-link {
  margin: 0 2rem;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-link::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}
.nav-link:hover::after,
.nav-link:focus::after {
  transform: scale(1);
}
</style>
