<template>
  <div class="background mt-10 lg:mt-24">
    <h1 class="font-rubik text-4xl mb-8 text-center">La carte</h1>
    <div class="w-1/2 mx-auto my-4"><hr /></div>

    <h3
      class="
        text-2xl
        font-poppins
        text-redcustom
        font-light
        text-center
        md:text-left md:my-5 md:ml-24
      "
    >
      Assortiments
    </h3>

    <div
      class="
        flex flex-col
        w-2/3
        2xl:w-2/5
        lg:flex-row lg:w-1/2
        rounded
        overflow-hidden
        shadow-lg
        m-2
        mx-auto
      "
    >
      <img
        class="w-full h-36 lg:w-64 lg:h-64 object-cover"
        src="../assets/assortiments.jpg"
        alt="Plats"
      />
      <div class="px-6 py-2">
        <h3 class="font-bold text-lg mb-2">Assortiment de Sawatdee</h3>
        <p class="text-gray-700 text-base"><strong>Nem</strong> (3 pièces)</p>
        <p class="text-gray-700 text-base"><strong>Rouleaux de printemps</strong> (2 pièces)</p>
        <p class="text-gray-700 text-base">
          <strong>Kai Satay</strong> - Brochettes de poulet (2 pièces)
        </p>
        <p class="text-gray-700 text-base"><strong>Kiaow Thord</strong> - Poulet frit (3 pièces)</p>
        <p class="text-gray-700 text-base">
          <strong>Khung Thord</strong> - Beignets de crevette (3 pièces)
        </p>
      </div>
      <p class="w-24 p-2 text-red-600 font-semibold flex items-end">15.00 €</p>
    </div>
    <tapas />
    <dishes />
  </div>
</template>
<script>
import Tapas from './menu/Tapas';
import Dishes from './menu/Dishes';

export default {
  components: {
    Tapas,
    Dishes,
  },
};
</script>
<style scoped>
.background {
  background-color: 'f7f7f7';
}
</style>
