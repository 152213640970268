<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      md:my-5 md:ml-24
      text-center
      md:text-left
    "
  >
    Spécialités de poulet
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-64 min-w-1/3 rounded overflow-hidden bg-white shadow-lg m-5">
      <img
        class="w-full h-40 object-cover"
        src="../../assets/Paad_piaon_wame_kai.jpg"
        alt="Sunset in the mountains"
      />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Paad Piaon Wame Kai</div>
        <p class="text-gray-700 text-base">Mélange de légumes sauce aigre-douce, poulet, ananas</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">11.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded overflow-hidden bg-white shadow-lg m-5">
      <img
        class="w-full h-40 object-cover"
        src="../../assets/khao_phad_kai.jpg"
        alt="Sunset in the mountains"
      />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Khao Phad Kai</div>
        <p class="text-gray-700 text-base">Riz parfumé sauté aux crevettes et aux légumes</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">11.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded overflow-hidden bg-white shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/panang_kai.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Panang Kai</div>
        <p class="text-gray-700 text-base">Recette à base de poulet, curry rouge, lait de coco</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">11.00 €</p>
    </div>
  </div>
</template>
