<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      text-center
      md:text-left md:my-5 md:ml-24
    "
  >
    Tapas
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <img
        class="h-32 rounded-2xl w-full object-cover shadow-md"
        src="../../assets/nem_poulet.jpg"
      />
      <div class="">
        <p class="text-md font-semibold text-gray-900 mb-0">Nem Poulet</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/nem_legumes.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900 mb-0">Nem Légumes</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/rouleau_printemps.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900 mb-0">Rouleau de printemps</p>
        <p class="text-md font-semibold text-red-600">2.00 €/pièce</p>
      </div>
    </div>

    <!-- Card 3 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/kay_satai.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900">Kai Satay</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>
    <!-- Card 4 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/khung_thord.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900">Beignets de crevettes</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>
    <!-- Card 5 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/Kiaow_thord.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900">Kiaow Thord</p>
        <p class="text-sm font-semibold text-gray-900">(Raviole crevettes)</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>
    <!-- Card 6 -->
    <div class="w-48 lg:w-1/5 flex flex-col mx-1 my-3">
      <div class="overflow-x-hidden">
        <img
          class="h-32 rounded-2xl w-full object-cover shadow-md"
          src="../../assets/samoussa_boeuf.jpg"
        />
      </div>
      <div class="">
        <p class="text-md font-semibold text-gray-900 mb-0">Samoussa boeuf</p>
        <p class="text-md font-semibold text-red-600">1.00 €/pièce</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped></style>
