<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      md:my-5 md:ml-24
      text-center
      md:text-left
    "
  >
    Spécialités Viandes
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/ka_pao_neau.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Ka Pao Neau</div>
        <p class="text-gray-700 text-base">
          Émincé de boeuf sauté au wok avec des feuilles de basilic thai
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/boeuf_huitre.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Boeuf sauce d'huitres</div>
        <p class="text-gray-700 text-base">Oignons, coriandre, boeuf</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
