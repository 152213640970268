<template>
  <div class="flex flex-col-reverse lg:flex-row bg-beige lg:p-8 xl:items-center">
    <div class="2xl:w-1/2 lg:w-1/2 my-8 lg:my-12">
      <carousel class="2xl:w-full 2xl:h-3/5 md:max-h-96 xl:max-h-full" />
    </div>
    <div class="lg:flex lg:flex-col lg:justify-center lg:items-center text-center lg:w-1/2 p-14">
      <p class="text-justify">
        Nok ce petit bout de femme, originaire de Chiang Mai, dans le nord de la Thaïlande, connaît
        mieux que personnes les secrets de la cuisine aux mille saveurs. Que ce soit les tapas à
        l'heure de l'apéro, les plats déclinés à la carte, à emporter, ou livrés, la cuisine de Nok
        est d'une subtile harmonie.
      </p>
      <p class="text-lg font-pacifico underline p-3 lg:p-4">
        « C'est une cuisine très saine, à base de légumes, mais aussi très parfumée, que la cuisson
        au wok met en valeur. »
      </p>
      <p class="text-justify">
        Beignets de crevettes, brochettes de viande ou de poissons, curry poulet à la pâte de
        piment, bœuf au curry rouge et lait de coco: le mariage des épices et plantes aromatiques
        (coriandre, menthe, gingembre, citronnelle, galanga…) est délicat et raffiné. Nok sait
        marier le piquant et le doux, le croustillant et le tendre. Un régal pour celui qui aime se
        dépayser en toute simplicité...
      </p>
    </div>
  </div>
</template>
<script>
import Carousel from './Carousel';
export default {
  components: {
    Carousel,
  },
};
</script>
<style scoped></style>
