<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      md:my-5 md:ml-24
      text-center
      md:text-left
    "
  >
    Dessert
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img
        class="w-full h-40 object-cover"
        src="../../assets/pancakes_banane.jpg"
        alt="pancake banane"
      />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Pancake banane</div>
        <p class="text-gray-700 text-base"></p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">5.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img
        class="w-full h-40 object-cover transform rotate-180"
        src="../../assets/dessert.jpg"
        alt="riz a la mangue"
      />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Kao Niao Ma Mouang</div>
        <p class="text-gray-700 text-base">riz, lait de coco, mangue</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">7.00 €</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
