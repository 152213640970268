<template>
  <mobile-nav class="w-64 mobile-transition z-100" v-if="mobileView" :class="{ open: showNav }" />

  <the-navigation v-if="!mobileView" />

  <div class="fixed text-black p-2 z-30" v-if="mobileView" @click="showNav = !showNav">
    <div v-if="showNav">
      <i class="fas fa-times text-white fa-2x"></i>
    </div>
    <div v-else>
      <i class="fa fa-bars fa-2x"></i>
    </div>
  </div>
  <div id="top-page">
    <top-page />
  </div>
  <div id="about">
    <about />
  </div>
  <div id="headband">
    <headband />
  </div>
  <div id="restaurant-menu">
    <restaurant-menu />
  </div>
  <div id="contact">
    <contact />
  </div>
</template>

<script>
import TheNavigation from './components/TheNavigation';
import MobileNav from './components/MobileNav';
import RestaurantMenu from './components/RestaurantMenu';
import About from './components/About';
import Contact from './components/Contact';
import TopPage from './components/TopPage';
import Headband from './components/Headband';

export default {
  components: {
    TheNavigation,
    Headband,
    About,
    Contact,
    RestaurantMenu,
    MobileNav,
    TopPage,
  },
  data() {
    return {
      mobileView: false,
      showNav: false,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 767;
    },
  },
  created() {
    this.handleView();
    window.addEventListener('resize', this.handleView);
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
.open {
  transform: translateX(300px);
  transition: 1s;
}

.mobile-transition {
  left: -300px;
  transition: 0.6s transform cubic-bezier(0, 0.12, 0.14, 1);
}
</style>
