<template>
  <div class="absolute left-1/2 top-2/3 title flex flex-col justify-center">
    <p class="text-center font-pacifico font-extrabold text-3xl md:text-5xl text-white">
      Traiteur Thai et plats à emporter
    </p>
    <a
      href="#restaurant-menu"
      class="font-poppins callback py-3 px-8 rounded mx-auto my-10 text-white"
      >Découvrir</a
    >
  </div>
  <img class="object-cover h-screen w-full" alt="" src="../assets/header.jpg" />
</template>

<script>
export default {
  components: {},
};
</script>
<style scoped>
.title {
  transform: translate(-50%, -50%);
}

.callback {
  background-color: rgba(0, 0, 0, 0.7);
}

.callback:hover {
  background-color: rgba(0, 0, 0, 0.4);
  transition: 300ms;
}
</style>
