<template>
  <h1 class="font-rubik text-3xl lg:text-4xl my-5 text-center">Contact</h1>
  <div class="flex flex-col lg:flex-row bg-gray-800">
    <div class="w-full lg:w-1/2">
      <div class="p-6 h-full flex flex-col justify-around font-poppins">
        <h4 class="text-2xl lg:text-4xl text-white font-caveat p-2 mb-4 text-center">
          Vente à emporter
        </h4>
        <p class="text-white mb-5 text-sm lg:text-base">
          Il est possible d'emporter directement votre commande en venant nous rencontrez sous les
          halles place monestier à Châteauroux
        </p>
        <div class="text-white text-sm text-center">
          <p class="">Le Jeudi de 9h00 à 14h00</p>
          <p class="">Le Vendredi de 9h00 à 14h00</p>
          <p class="">Le Samedi de 9h00 à 14h00</p>
        </div>
        <p class="text-white text-center mt-4">
          <a href="">
            <i class="fab fa-facebook-square fa-2x"></i>
          </a>
        </p>
      </div>
    </div>
    <div class="w-full lg:w-1/2">
      <div class="w-full">
        <iframe
          class="w-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2730.592500391001!2d1.6886933147988739!3d46.812332450499454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47fba0a7845fb501%3A0x2eab30f61eaa7c67!2sPl.%20Robert%20Monestier%2C%2036000%20Ch%C3%A2teauroux!5e0!3m2!1sfr!2sfr!4v1637253185358!5m2!1sfr!2sfr"
          width="600"
          height="350"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped></style>
