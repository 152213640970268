<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      md:my-5 md:ml-24
      text-center
      md:text-left
    "
  >
    Spécialités de Sawatdee
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/Phad_thai_koung.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-36">
        <div class="font-bold text-xl mb-2">Phad Thaï Koung (Sauté de nouilles Thaï)</div>
        <p class="text-gray-700 text-base">
          Crevettes, oeuf, oignon vert, nouilles de riz, germe de soja
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/phad_thai_kai.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-36">
        <div class="font-bold text-xl mb-2">Phad Thaï Kai (Sauté de nouilles Thaï)</div>
        <p class="text-gray-700 text-base">
          Poulet, oeuf, oignon verte, nouilles de riz, germe de soja
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img
        class="w-full h-40 object-cover"
        src="../../assets/pad_thai_pak.jpg"
        alt="Plat
      "
      />
      <div class="px-6 py-4 flex flex-col justify-between h-36">
        <div class="font-bold text-xl mb-2">Phad Thaï Pak</div>
        <p class="text-gray-700 text-base">
          Mélange de légumes, oeuf, nouilles de riz, gerle de soja
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/soupe_coco.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-24">
        <div class="font-bold text-xl mb-2">Soupe Poulet Coco</div>
        <p class="text-gray-700 text-base">Poulet, lait de coco</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/soupe_crevette.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-24">
        <div class="font-bold text-xl mb-2">Soupe Crevettes</div>
        <p class="text-gray-700 text-base">Crevettes, champignons, citronelle</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/soupe_mer.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-24">
        <div class="font-bold text-xl mb-2">Soupe de la mer</div>
        <p class="text-gray-700 text-base">Crevettes, encornets, poisson, moules, citronelle</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/salade_crevette.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-24">
        <div class="font-bold text-xl mb-2">Salade Vermicelles Poulet ou Crevettes</div>
        <p class="text-gray-700 text-base"></p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/saumon_coco.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-16">
        <div class="font-bold text-xl mb-2">Saumon, lait de coco</div>
        <p class="text-gray-700 text-base"></p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>

    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/chipiron.jpg" alt="Plat" />
      <div class="px-6 py-4 flex flex-col justify-between h-16">
        <div class="font-bold text-xl mb-2">Chipiron sauté basilic Thaï</div>
        <p class="text-gray-700 text-base"></p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
