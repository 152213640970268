<template>
  <h2 class="font-rubik text-2xl my-5 text-center">
    Plats (Accompagnement : riz parfumé et nature)
  </h2>
  <chicken />
  <meat />
  <fish />
  <specialities />
  <dessert />
</template>

<script>
import Chicken from './Chicken';
import Meat from './Meat';
import Fish from './Fish';
import Specialities from './Specialities';
import Dessert from './Dessert';
export default {
  components: {
    Chicken,
    Meat,
    Fish,
    Specialities,
    Dessert,
  },
};
</script>
<style scoped></style>
