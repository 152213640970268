<template>
  <h3
    class="
      text-2xl
      font-poppins
      text-redcustom
      font-light
      md:my-5 md:ml-24
      text-center
      md:text-left
    "
  >
    Les poissons et crevettes
  </h3>
  <div class="flex flex-wrap justify-center">
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/kapaao_khong.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Kapaao Khong</div>
        <p class="text-gray-700 text-base">
          Crevettes sautées au wok avec des feuilles de basilic thai
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">12.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img
        class="w-full h-40 object-cover"
        src="../../assets/Phad_piaon_wane_khong.jpg"
        alt="Plat"
      />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Phad Piaon Wane Khong</div>
        <p class="text-gray-700 text-base">
          Mélange de légumes sauce aigre-douce, crevettes, ananas
        </p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">11.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/Khao_phad_khong.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Khao Phad Khong</div>
        <p class="text-gray-700 text-base">Riz parfumé sauté au poulet et aux légumes</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">11.00 €</p>
    </div>
    <div class="w-64 min-w-1/3 rounded bg-white overflow-hidden shadow-lg m-5">
      <img class="w-full h-40 object-cover" src="../../assets/phad_pak_rouammit.jpg" alt="Plat" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">Phad Pak Rouammit</div>
        <p class="text-gray-700 text-base">Mélange de légumes sauté au wok</p>
      </div>
      <p class="float-right mx-3 my-2 text-red-600 font-semibold">10.00 €</p>
    </div>
  </div>
</template>
