<template>
  <div class="background w-full rounded lg:-mt-12 lg:absolute lg:h-24 lg:w-1/2 shadow-lg">
    <div class="h-full flex items-center text-center text-sm lg:text-base">
      <div class="h-full text-white w-1/2 border-r-2 border-white">
        <i class="fas fa-shipping-fast fa-2x p-3"></i>
        <p>Livraison 7/7 gratuite</p>
      </div>
      <a
        class="h-full text-white w-1/2 hover:bg-gray-800 transition duration-300"
        href="tel:06-26-64-50-77"
      >
        <div>
          <i class="fas fa-phone-square-alt fa-2x p-3"></i>
          <p>06 26 64 50 77</p>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
@media screen and (min-width: 1080px) {
  .background {
    margin-left: 25%;
  }
}
@media screen and (min-width: 1024px) {
  .background {
    margin-left: 25%;
  }
}

.background {
  background-color: #4b3016;
}
</style>
